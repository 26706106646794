// Links.js
import React from 'react';
import './YoutubeCSS.css';


const Oefeningen = () => {
  return (
    <div className="book-promo">
      <div className="book-promo__content">
        <div className="book-promo__text">
      <h1>Oefeningen</h1>

      <h2>Bosanske rijeci 1</h2>
      <div className="video-container">
      
        <iframe
          width="560"
          height="315"
          src="https://scratch.mit.edu/projects/944489254/embed"
          title="Bosanske rijeci 1"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>
      <h2>Bosanske rijeci 2</h2>
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://scratch.mit.edu/projects/956781092/embed"
          title="Recenice 2"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>
      <h2>Recenice  1</h2>
      <div className="video-container">
      
        <iframe
          width="560"
          height="315"
          src="https://scratch.mit.edu/projects/956134442/embed"
          title="Recenice 1"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>
      <h2>Recenice  2</h2>
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://scratch.mit.edu/projects/956796191/embed"
          title="Recenice 2"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>
      



      {/* <ul>
        <li><a href="https://scratch.mit.edu/projects/944489254/">Bosanske rijeci 1</a></li>
        <li><a href="https://scratch.mit.edu/projects/956781092/">Bosanske rijeci 2</a></li>
        <li><a href="https://example.com/link3">Link 3</a></li>
        <li><a href="https://example.com/link4">Link 4</a></li>
      </ul>
      <a href="https://scratch.mit.edu/projects/944489254/" target="_blank" rel="noopener noreferrer">
      Bosanske rijeci 1
</a>
<a href="https://scratch.mit.edu/projects/956781092/" target="_blank" rel="noopener noreferrer">
Bosanske rijeci 2
</a>
<a href="https://scratch.mit.edu/projects/956134442/" target="_blank" rel="noopener noreferrer">
Recenice 1
</a>
<a href="https://scratch.mit.edu/projects/956796191/" target="_blank" rel="noopener noreferrer">
recenice 2
</a> */}


    </div>
    </div>
    </div>
  );
};

export default Oefeningen;