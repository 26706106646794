// Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css'; // Import the CSS file for styling

const Navbar = () => {
  return (
    <nav>
      <ul>
        <li><Link to="/">Home</Link></li>
{/*         <li><Link to="/videos">Videos</Link></li>
 */}        <li><Link to="/oefeningen">Oefeningen</Link></li>
      </ul>
    </nav>
  );
};

export default Navbar;