import book1 from './book.jpg';
import React, { useState} from 'react';
import './BookPromo.css';





function ContactForm({ showForm, setShowForm }) {
 
  
   

  return showForm ? (
    

    <form className="book-promo__form" method="POST" name="contact" data-netlify="true" netlify-honeypot="phone-field">
  <input type="hidden" name="form-name" value="contact" />
  <label htmlFor="name">Voornaam:</label>
  <input
    type="text"
    id="name"
    name="name"
    required
    maxlength="100"
  />
  <label htmlFor="lastname">Achternaam:</label>
  <input
    type="text"
    id="lastname"
    name="lastname"
    required
    maxlength="100"
  />
  <label htmlFor="email">Email:</label>
  <input
    type="email"
    id="email"
    name="email"
    required
  />
  <label htmlFor="message">Message:</label>
  <textarea
    id="message"
    name="message"
    required
  />
  <div className="checkbox-and-label">
  <label htmlFor="GDPRChecked">
    <input
      type="checkbox"
      id="GDPRChecked"
      name="GDPRChecked"
      required
    />
    Ja, mijn gegevens mogen worden gebruikt voor de goede afhandeling van mijn vraag of opmerking. Zij worden in geen geval verder gestuurd naar een derde partij.
  </label>
  
  <label htmlFor="newsletter">
    <input
      type="checkbox"
      id="newsletter"
      name="newsletter"
    />
    Ik wil me aanmelden voor de nieuwsbrief.
  </label>
  
  </div>
  
  <button type="submit">Submit</button>
  <button type="button" onClick={() => setShowForm(false)}>Close</button>
</form>

  ) : (
    <>
      <button className="book-promo__contact-button" onClick={() => setShowForm(true)}>Contact</button>
      
    </>
  );
};



const BookPromo = () => {
  const [showForm, setShowForm] = useState(true);
 

  return (
    <div className="book-promo">
      <div className="book-promo__content">
        <div className="book-promo__text">
        
  
        
  
  
  <h1><u>Over dit boek</u></h1>
    <p>
    <i><b>Bosanski – Handboek voor Bosnisch-Nederlandstaligen</b></i> bevat alle grammaticale regels van de 
      Bosnische taal. Het is een geschikt zelfstudieboek die de Bosnische regels in het Nederlands uitlegt. 
      Daarnaast zijn er bij elk hoofdstuk ook oefeningen. Het boek heeft in totaal 180 pagina’s.
    </p>
    <h2><u>Waarom is dit boek belangrijk?</u></h2>
    <p>
      Tot anno 2023 was er geen boek op de Nederlandstalige markt die een brede uitleg geeft van de 
      Bosnische taalregels. De jeugd en de volwassenen die ondertussen het Bosnische niet volledig 
      hebben kunnen beheersen, hebben weinig mogelijkheden om hun moedertaalvaardigheden te verbeteren. 
    </p>
    <p>
      Als het je moeite vergt om een taal te spreken dan zul je die taal in je dagelijks leven minder gebruiken, 
      met als gevolg dat de ontwikkeling van die taal stagneert. Bij kinderen zal dit ertoe leiden dat hun Bosnisch 
      onafgewerkt blijft. Eens ze volwassen zijn, kunnen ze hun gedachten niet verwoorden zoals ze het in het Nederlands 
      wel kunnen. Als ze dan zelf kinderen krijgen en hun tweetalig willen opvoeden (Nederlands en Bosnisch), dan komen 
      ze in een dilemma: 
    </p>
    <p>
      Als het voor de ouder belangrijker is om met zijn kinderen te communiceren en zijn gedachten uit te leggen in 
      het Nederlands, de taal die hij goed beheerst en zonder al te veel moeite, dan ontwikkelen zijn kinderen helemaal 
      geen Bosnische taalvaardigheden. Is het voor hem echter belangrijker om Bosnisch te spreken, ook al is dit met moeite, 
      dan gaat dat ten koste van wat men eigenlijk wil zeggen, vooral bij complexere thema’s. Het kind leert dan wel Bosnisch 
      maar zal ook moeite ondervinden bij het spreken.
    </p>
    <p>
      Kinderen die hun moedertaal gebrekkig of helemaal niet kunnen spreken, zullen meer kans hebben om later met psychologische en emotionele problemen te kampen. Dit boek wil dit verhinderen door uw omgeving, en de hunne, met het Bosnisch te verrijken. Iedereen moet de kans hebben om op zijn eigen tempo en op zijn eigen formaat de Bosnische taal aan te leren.
</p>
<p>
Het belangrijkste doel is om ervoor te zorgen dat je geen moeite ervaart en het niet te veel energie vergt om Bosnisch te spreken. Het gaat erom om negatieve gevolgen te vermijden, en potentiële positieve kansen niet te verspelen.
</p>
<h2><u>Hoe dit boek gebruiken?</u></h2>
<p>
Het eerste wat je doet, is even door het boek heen bladeren en het globaal te lezen. Zo zie je wat er alles in staat en waarmee je problemen hebt. De overige inhoud die je al kent laat je dan links liggen. Ieders thuissituatie en Bosnisch taalniveau is anders en daarom heeft dit boek ook zo’n brede inhoud.
</p>
<h3>Overtuigd? Contacteer ons dan voor meer informatie.</h3>


        </div>
        <div className="book-promo__image">
          <img src={book1} alt="Book Cover" />
        </div>
      </div>
              <ContactForm showForm={showForm} setShowForm={setShowForm} />
    </div>
  );
};

export default BookPromo;
