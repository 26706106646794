// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Navbar';
import Home from './Home';
/* import Videos from './Videos';
 */import Oefeningen from './Oefeningen';

const App = () => {
  return (
    <Router>
      <Navbar />
  <Routes>
    <Route path="/" element={<Home />} />
    {/* <Route path="/videos" element={<Videos />} /> */}
    <Route path="/oefeningen" element={<Oefeningen />} />
  </Routes>
</Router>
  );
};

export default App;